import { ResetIcon } from '@radix-ui/react-icons'
import { LinksFunction, LoaderFunctionArgs, SerializeFrom } from '@remix-run/node'
import {
  isRouteErrorResponse,
  json,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError } from '@sentry/remix'
import { clsx } from 'clsx'
import React from 'react'
import { PreventFlashOnWrongTheme, Theme, ThemeProvider, useTheme } from 'remix-themes'
import { Button } from '~/components/ui/button'
import { CommerceBearIcon } from '~/components/ui/commerce-bear-icon'
import { Typography } from '~/components/ui/typography'
import { currentUserContext } from './auth.server'
import env from './lib/env.server'
import './tailwind.css'

type Env = { SENTRY_ENV: string; SENTRY_DSN: string; DEBUG: boolean }

type UserContext = {
  user: {
    id: string
    email: string
    givenName: string
    familyName: string | null
  }
  organization: {
    id: string
    name: string
  }
}

declare global {
  interface Window {
    ENV: Env
    USER_CONTEXT: UserContext | null
  }
}

export const links: LinksFunction = () => [
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Outfit:wght@400;600&display=swap',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Anton&family=Outfit:wght@400&display=swap',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '48x48',
    href: '/favicon/favicon-48x48.png',
  },
  {
    rel: 'icon',
    type: 'image/svg+xml',
    href: '/favicon/favicon.svg',
  },
  {
    rel: 'shortcut icon',
    href: '/favicon/favicon.ico',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/favicon/apple-touch-icon.png',
  },
  { rel: 'manifest', href: '/favicon/site.webmanifest' },
]

export async function loader({ request }: LoaderFunctionArgs) {
  // const { getTheme } = await themeSessionResolver(request) TODO stevo: use dynamic theme when dark theme is ready and theme switcher enabled
  const userContext = await currentUserContext(request)
  const currentContext = userContext
    ? {
        user: {
          id: userContext.user.id,
          email: userContext.user.email,
          givenName: userContext.user.givenName,
          familyName: userContext.user.familyName,
        },
        organization: {
          id: userContext.organization.id,
          name: userContext.organization.name,
        },
      }
    : null

  return json({
    ENV: {
      SENTRY_ENV: env.NODE_ENV,
      SENTRY_DSN: env.SENTRY_DSN,
      DEBUG: env.NODE_ENV !== 'production',
    },
    // theme: getTheme(), TODO stevo: use dynamic theme when dark theme is ready and theme switcher enabled
    theme: Theme.LIGHT,
    USER_CONTEXT: currentContext,
  })
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root')

  return (
    <ThemeProvider specifiedTheme={data?.theme ?? null} themeAction="/action/set-theme">
      <InnerLayout ENV={data?.ENV} ssrTheme={Boolean(data?.theme)} USER_CONTEXT={data?.USER_CONTEXT}>
        {children}
      </InnerLayout>
    </ThemeProvider>
  )
}

function InnerLayout({
  ENV,
  ssrTheme,
  children,
  USER_CONTEXT,
}: {
  ENV: SerializeFrom<Env> | undefined
  ssrTheme: boolean
  children: React.ReactNode
  USER_CONTEXT: SerializeFrom<UserContext | null> | undefined
}) {
  const [theme] = useTheme()

  return (
    <html lang="en" className={clsx(theme)}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="apple-mobile-web-app-title" content="CommerceBear" />
        <Meta />
        <PreventFlashOnWrongTheme ssrTheme={ssrTheme} />
        <Links />
      </head>
      <body style={{ margin: 0 }}>
        {children}
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.ENV = ${JSON.stringify(ENV)};
              window.USER_CONTEXT = ${JSON.stringify(USER_CONTEXT)};
            `,
          }}
        />
        <Scripts />
      </body>
    </html>
  )
}

export const ErrorBoundary = () => {
  const error = useRouteError()
  console.log(error)
  captureRemixErrorBoundaryError(error)

  return (
    <>
      {isRouteErrorResponse(error) ? (
        <div className="h-screen px-6 py-24 sm:py-32 lg:px-48">
          <div className="space-y-12">
            <div className="flex justify-center items-center">
              <Link to="/">
                <CommerceBearIcon width={30} height={32} />
              </Link>
            </div>
            <div className="text-center space-y-4">
              <Typography variant="h1">{error.status}</Typography>
              <Typography variant="muted">{error.data}</Typography>
            </div>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Button asChild>
                <Link to="/">
                  Go back home <ResetIcon className="ml-2 h-4 w-4" />
                </Link>
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {(() => {
            // Implementation inspired by default ErrorBoundary: https://github.com/remix-run/remix/blob/main/packages/remix-react/errorBoundaries.tsx
            let errorInstance: Error
            if (error instanceof Error) {
              errorInstance = error
            } else {
              const errorString =
                error == null
                  ? 'Unknown Error'
                  : typeof error === 'object' && 'toString' in error
                    ? error.toString()
                    : JSON.stringify(error)
              errorInstance = new Error(errorString)
            }

            // Remix automatically strips error messages and stack traces in production
            // https://remix.run/docs/en/main/guides/errors#error-sanitization
            return (
              <div className="p-6 space-y-6">
                <h1 className="text-2xl">{errorInstance.constructor.name}</h1>
                <pre className="p-8 text-red-500 bg-[hsla(10,50%,50%,0.1)] overflow-auto">
                  {errorInstance.stack ?? errorInstance.message}
                </pre>
              </div>
            )
          })()}
        </>
      )}
    </>
  )
}

export default function App() {
  return <Outlet />
}
